import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/research/medicine/001.jpg';
// import blogd2 from '../../assets/images/2022/federal-seminar/2.jpg';
// import blogd3 from '../../assets/images/2022/federal-seminar/3.jpg';
// import blogd4 from '../../assets/images/2022/federal-seminar/4.jpg';
// import blogd5 from '../../assets/images/2022/federal-seminar/5.jpg';
// import blogd6 from '../../assets/images/2022/federal-seminar/6.jpg';
// import blogd7 from '../../assets/images/2022/federal-seminar/7.jpg';
// import blogd8 from '../../assets/images/2022/federal-seminar/8.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              {/* <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div> */}
              {/* <h2>
                LUTH Guidelines for Antibiopsy Policy
              </h2> */}
              {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                Prevention
              </span> */}
              {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                Maintain optimal body weight, physical exercise, (30 to 45mins
                of moderate exercise per day) healthy diet. Limit sugary and
                alcoholic beverages, Smoking cessation
              </p> */}

              <div className='text-center mt-5'>
                <h5 style={{ marginTop: 40, marginBottom: 10 }}>
                  Download/View Full Document (PDF)
                </h5>
                <iframe
                  src='/pdfs/antibiotic-policy.pdf'
                  width='100%'
                  height='600px'
                  style={{ border: '1px solid #ccc', borderRadius: '8px' }}
                  title='Diabetes Overview PDF'
                />
                <br />
                <a
                  href='/'
                  download='Diabetes-Overview.pdf'
                  className='btn btn-primary mt-3'
                  style={{
                    display: 'inline-block',
                    padding: '10px 20px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    textDecoration: 'none',
                  }}
                >
                  Download PDF
                </a>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Department of Medicine</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
